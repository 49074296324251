import React, {useState, useEffect} from 'react';
import {Table, Container, Toast, Button, Tooltip, OverlayTrigger, Row, Col} from 'react-bootstrap'
import Layout from "../components/layout"
import RightSidebar from "../components/sidebar/rightSideBar"

import SEO from "../components/seo";
import EditGrouping from '../components/groupings/editGrouping';
import AddGrouping from '../components/groupings/addGrouping';
import StandardModal from '../components/modal';

import Fetch from '../helpers/fetch';
import constants from '../helpers/constants';
import { isAdmin, isManager } from '../helpers/roles'

const OrderGroupings = () => {
  const emptyEditItem = {
    id: '',
    groupName: '',
    custInCharge: '',
    name:'',
    phone:'',
    email:''
  }
  const [groupings, setGroupings] = useState([])
  const [deleteId, setDeleteId] = useState(0);
  const [editItem, setEditItem] = useState({});

  const [showLoader, setShowLoader] = useState(false);
  const [groupingArray, setGroupingArray] = useState([]);
  // const [pagination, setPagination] = useState({});

  const [groupingId, setGroupingId] = useState(0);
  const [showEditGrouping, setShowEditGrouping] = useState(false);
  const [showAddGrouping, setShowAddGrouping] = useState(false);
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const [allowedViews, setAllowedViews] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [errorMessage, seterrorMessage] = useState('')

  const [showRightSidebar, setshowRightSidebar] = useState(false)



  const getGroupings = () => {
    let url = `${constants.BACKEND_URL}/order-groupings`
    Fetch.get(url).then(data => setGroupings(data.data));
  }

  useEffect(() => {
    getGroupings()
    return () => {
      
    }
  }, [])

  const handleOnDeleteClick = (e) => {
    setDeleteId(e.target.dataset.deleteId);
    setShowDeleteModal(true)
  }

  const handleOnEditClick = (e) => {
    setEditItem(JSON.parse(e.target.dataset.value));
    setShowEditGrouping(true);
    setShowSummaryTable(false);
  }

  const handleOnAddGroupClick = (e) => {
    setEditItem(emptyEditItem);
    setShowAddGrouping(true);
    setShowSummaryTable(false);
    setshowRightSidebar(false);

  }

  const handleItemChange = (e) => {
    const changedProperty = {}
    switch (e.target.dataset.property) {
      case 'custInCharge':
          const custId = e.target.value
          changedProperty.custInCharge = custId;
          setEditItem({...editItem, ...changedProperty});
          const url = `${constants.BACKEND_URL}/customers/${custId}`
          Fetch.get(url).then(data => {
            changedProperty.name = data.data.name;
            changedProperty.phone = data.data.phone;
            changedProperty.email = data.data.email;
            setEditItem({...editItem, ...changedProperty});
          }).catch(e => {
            changedProperty.name = '';
            changedProperty.phone = '';
            changedProperty.email = '';
            setEditItem({...editItem, ...changedProperty});
          })
        break;
      case 'groupName':    
        const groupName = e.target.value;
        changedProperty.groupName = groupName;
        setEditItem({...editItem, ...changedProperty});
        break;
      default:
        break;
    }
    
    
  }

  const saveEditItem = (e) => {
    const url = `${constants.BACKEND_URL}/order-groupings`
    if(showAddGrouping) {
      Fetch.post(url, JSON.stringify(editItem))
      .then((res) => {
        if(res.error) {
          setShowToast(true);
          seterrorMessage(res.error);
          return;
        }
        if (res.data) {
          setGroupings(res.data)
          setShowSummaryTable(true);
          setShowAddGrouping(false);
        }
      })
    } else {
      Fetch.put(`${url}/${editItem.id}`, JSON.stringify(editItem))
      .then((res) => {
        if(res.error) {
          setShowToast(true);
          seterrorMessage(res.error);
          return;
        }
        setGroupings(res.data)
        setShowSummaryTable(true);
        setShowEditGrouping(false);
      })
    }
  }

  const cancelEditItem = (e) => {
    setShowSummaryTable(true);
    setShowAddGrouping(false);
    setShowEditGrouping(false);
  }

  const getOrderItemFromOrdersArray = (orderItemId) => {
    if (groupings.length > 0) {
      const orderItem = groupings.filter(item => {
        return item.id == Number(orderItemId)
      })
      return orderItem[0];
    }
  }

  const handleModalClose = () => {
    setShowDeleteModal(false);
  }

  const handleModalConfirm = () => {
    Fetch.delete(`${constants.BACKEND_URL}/order-groupings/${deleteId}`).then((res) => {
      if(res.error) {
        setShowToast(true);
        seterrorMessage(res.error);
        return;
      }
      setGroupings(res.data)
      setShowSummaryTable(true);
      setShowEditGrouping(false);
    })
    setShowDeleteModal(false);
  }

  const toggleRightSidebar = (e) => {
    setEditItem(getOrderItemFromOrdersArray(e.target.dataset.id))
    if (Number(e.target.dataset.custId) !== Number(editItem.custInCharge)) {
      setshowRightSidebar(!showRightSidebar)
      setshowRightSidebar(true);
    } else {
      setshowRightSidebar(!showRightSidebar)
    }
  }

  const handleOnSidebarClose = () => {
    setshowRightSidebar(false);
  }


  return (
    <Layout pageInfo={{ pageName: "orderGroupings" }}>
      {/* <Loader show={showLoader}></Loader> */}
      <SEO title="Order Groupings" />
        <Row>
          <Col className="text-center">
            <h5>Manage Groups</h5>
          </Col>
        </Row>
        { showSummaryTable && 
          <Container className="text-center" >
            <Row className="mb-3">
              <Col lg={2} md="4" xs="6" className="text-left">
                <Button onClick={handleOnAddGroupClick}>Add Grouping</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Group Name</th>
                      <th>
                        Customer In Charge (Cust ID)
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip className='text-left' id={`tooltip`}>
                              Click on Cust Id to see additional info
                            </Tooltip>
                          }
                        >
                          <span className="pointer oi oi-question-mark oi-question-mark-custom"></span>
                        </OverlayTrigger>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupings && Array.isArray(groupings) ? 
                      groupings.map(item => {
                        return (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.groupName}</td>
                            <td>
                              <span className="pointer" data-cust-id={item.custInCharge} data-id={item.id} onClick={toggleRightSidebar}>{item.custInCharge}</span>
                            </td>
                            <td>
                              <Button className="mr-1" data-value={JSON.stringify(item)} onClick={handleOnEditClick}>Edit</Button>
                              { 
                                (isAdmin() || isManager()) && 
                                <Button variant="danger" data-delete-id={item.id} onClick={handleOnDeleteClick}>Delete</Button>
                              }
                            </td>
                          </tr>
                        )
                      }) : ''
                    }
                  </tbody>
                </Table>  
              </Col>
            </Row>
            
          </Container>
        }
        <EditGrouping 
          show={showEditGrouping} 
          item={editItem} 
          handleItemChange={handleItemChange}
          saveEditItem={saveEditItem}
          cancelEditItem={cancelEditItem}
        ></EditGrouping>
        <AddGrouping 
          show={showAddGrouping} 
          item={editItem} 
          handleItemChange={handleItemChange}
          saveEditItem={saveEditItem}
          cancelEditItem={cancelEditItem}
        ></AddGrouping>

        <StandardModal 
          onConfirm={handleModalConfirm}
          onClose={handleModalClose} 
          show={showDeleteModal}
          title="Delete Grouping"
          body={`Are you sure you want to delete Grouping?`}
        >
        </StandardModal>
        <Toast 
          show={showToast} 
          onClose={() => {setShowToast(false)}}
          autohide
          style={{
            position: 'absolute',
            top: '1vh',
            'minHeight': '2vh',
            right: 0,
          }}
        >
          
            <Toast.Body><span className="oi oi-warning text-danger"></span><strong className="mr-auto text-danger">{errorMessage}</strong></Toast.Body>
        </Toast>
        <RightSidebar 
        show={showRightSidebar}
        closeFn={handleOnSidebarClose}
        state={editItem}
        >
          <h5 className="text-center mb-2">Additional Info</h5>  
          <Table>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{editItem.name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{editItem.phone}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{editItem.email}</td>
              </tr>
            </tbody>
          </Table>
        </RightSidebar>
    </Layout>
  )
}

export default OrderGroupings