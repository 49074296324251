import React, { useState } from 'react';
import {Form, Col, Row, Container, Button} from 'react-bootstrap'
import constants from '../../helpers/constants';

const EditGrouping = ({show, showAddForm, item, handleItemChange, saveEditItem, cancelEditItem}) => {
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    event.preventDefault();
    saveEditItem();
  };

  const cancelAddGrouping =(e) => {
    setValidated(false);
    cancelEditItem();
  }

  return (
    <>
    { 
        show &&
        <Container fluid>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {!showAddForm &&
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  ID
                </Form.Label>
                <Col sm={10}>
                  <Form.Control 
                    required
                    data-property="id" 
                    type="text" 
                    placeholder="" 
                    value={item['id']} 
                    readOnly={true}
                    />
                </Col>
              </Form.Group>
            }
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Group Name
              </Form.Label>
              <Col sm={10}>
                <Form.Control 
                  required
                  data-property="groupName" 
                  type="text" 
                  placeholder="Please enter Group name" 
                  value={item['groupName']} 
                  onChange={handleItemChange} 
                  />
                <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Cust Id
              </Form.Label>
              <Col sm={10}>
                <Form.Control 
                  required
                  data-property={'custInCharge'} 
                  type="number" 
                  step="1"
                  placeholder={'Please enter Cust Id'} 
                  value={item['custInCharge']} 
                  onChange={handleItemChange} 
                  />
                <Form.Control.Feedback type="invalid">Please enter Cust Id</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Cust Name
              </Form.Label>
              <Form.Label column sm={4}>
                {item.name}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Cust Phone
              </Form.Label>
              <Form.Label column sm={4}>
                {item.phone}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                Cust Email
              </Form.Label>
              <Form.Label column sm={4}>
                {item.email}
              </Form.Label>
            </Form.Group>
          
          <Button className="mr-1" type="submit">Save</Button>
          <Button variant="danger" onClick={cancelAddGrouping}>Cancel</Button>
          </Form>
        </Container>
      } 
    </>
  )
}

export default EditGrouping